<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container" style="position: relative;padding-right: 140px;">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="活动名称" style="width: 200px;"></el-input>
			</div>
			<!-- 活动状态 -->
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addPackagePrice'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddCoupon">添加</el-button>
				</button-permissions>
			</div>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading='loading'>
				<el-table-column prop="Name" label="活动名称"></el-table-column>
				<el-table-column label="活动时间">
					<template slot-scope="scope">
						{{scope.row.StartTime}}
						<span>至</span>
						{{scope.row.EndTime}}
					</template>
				</el-table-column>
				<el-table-column prop="BalePrice" label="活动内容">
					<template slot-scope="scop">
						{{scop.row.BalePrice}}元{{scop.row.BaleNum}}件
					</template>
				</el-table-column>
				<el-table-column prop="ActivityState" label="状态">
					<template slot-scope="scop">
						<div v-if="scop.row.ActivityState === 0">未开始</div>
						<div v-if="scop.row.ActivityState === 1">进行中</div>
						<div v-if="scop.row.ActivityState === 2">已结束</div>
					</template>
				</el-table-column>
				<el-table-column label="支付订单">
					<template slot-scope="scope">
						<span v-if="scope.row.PayCount==0">0</span>
						<el-button v-else type="text" @click="handleRouteJump(scope.row)">{{scope.row.PayCount}}</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="BuyerNum" label="支付人数"></el-table-column>
				<el-table-column label="支付金额">
					<template slot-scope="scope">
						<span>{{+scope.row.PayAmount}}</span>
					</template>
				</el-table-column>
				<el-table-column label="客单价">
					<template slot-scope="scope">
						<span>{{+scope.row.BuyerUnitPrice}}</span>
					</template>
				</el-table-column>
				<el-table-column label="笔单价">
					<template slot-scope="scope">
						<span>{{+scope.row.PayUnitPrice}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scop">
						<div v-if="scop.row.ActivityState!= 2">

							<button-permissions :datas="'editPackagePrice'">
								<div class="table-button" @click="handleTableEdit(scop.row)">编辑</div>
							</button-permissions>
							
							<button-permissions :datas="'packagePriceTg'">
								<div class="table-button"  @click="handleTablePromote(scop.row.Id)">推广</div>
							</button-permissions>

							<el-dropdown>
								<el-button type="text">
									更多
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item >
										<button-permissions :datas="'cancelPackagePrice'">
											<div class="table-button" @click="handleTableFail(scop.row.Id)">使失效</div>
										</button-permissions>
									</el-dropdown-item>

									<el-dropdown-item>
										<button-permissions :datas="'copyPackagePrice'">
											<div class="table-button" @click="handleTableCopy(scop.row)">复制</div>
										</button-permissions>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>

						<div v-else>
							<div class="table-button" @click="handleTableEdit(scop.row)">查看</div>

							<button-permissions :datas="'copyPackagePrice'">
								<div class="table-button" @click="handleTableCopy(scop.row)">复制</div>
							</button-permissions>

							<button-permissions :datas="'deletePackagePrice'">
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scop.row.Id)">删除</div>
							</button-permissions>
						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
	import {
		activityBaleList,
		baleClose,
		baleDelete
	} from '@/api/goods'

	import buttonPermissions from '@/components/buttonPermissions';
	import batchCods from "@/components/bathCodes"
	
	import experienceCode from '@/components/experienceCode';
	
	export default {
		components: {
			buttonPermissions,
			batchCods,
			experienceCode
		},
		data() {
			return {
				weiCodeData: {},
				weiCodeShow: false,
				bathSortDatas: [],
				loading: false,
				searchKey: '',
				status: null,
				tableData: [],
				statusOptions: [{
						value: 0,
						label: '未开始'
					},
					{
						value: 1,
						label: '进行中'
					},
					{
						value: 2,
						label: '已结束'
					}
				],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				// edit
				showDataDialog: false
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getList() {
				this.loading = true;
				try {
					let data = {
						Keywords: this.searchKey, // 搜索关键字
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
						ActivityState: this.status
					}
					let result = await activityBaleList(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];
					this.tableData.forEach(t => {
						t.PayAmount = t.PayAmount.toFixed(2)
						t.BuyerUnitPrice = t.BuyerUnitPrice.toFixed(2)
						t.PayUnitPrice = t.PayUnitPrice.toFixed(2)
					})

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleFilter() {
				this.page.current = 1;
				this.getList()
			},
			//支付订单跳转
			handleRouteJump(val) {
				this.$router.push({
					path: '/order/orderMange',
					query: {
						ActivityId: val.Id,
						ActivityType: 3,
						starTime: val.StartTime,
						endTime: val.EndTime,
					}
				})
			},
			handleAddCoupon() {
				this.$router.push({
					path: '/market/packPrice/editPriceDetaile'
				})
			},
			handleTableEdit(val) {
				let activeState = 1
				if (val.ActivityState == 0) {
					activeState = 1
				} else if (val.ActivityState == 2) {
					activeState = 3
				} else {
					activeState = 2
				}
				this.$router.push({
					path: '/market/packPrice/editPriceDetaile',
					query: {
						id: val.Id,
						activeState: activeState
					}
				})
			},
			handleTableCopy(record) {
				this.$router.push({
					path: '/market/packPrice/editPriceDetaile',
					query: {
						id: record.Id,
						copy: 1
					}
				})
			},
			// table使失效
			handleTableFail(id) {
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使失效？', '提示', {
					confirmButtonText: '确认失效',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureStop(id)
				}).catch(() => {}).finally(() => {})
			},
			async sureStop(id) {
				const res = await baleClose({
					Id: id
				})
				if (res.IsSuccess) {
					this.$message({showClose: true,
						message: '终止成功',
						type: 'success'
					})
					this.getList();
				}
			},
			// table删除
			handleTableDelete(id) {

				this.$confirm('是否确认删除活动？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureDelete(id)
				}).catch(() => {}).finally(() => {})
			},
			// 推广
			handleTablePromote(id) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: 'pages/f/f',
					Scene: `id=${id}`
				}
			},
			weiCodeCancel(val) {
				this.weiCodeShow = false;
			},
			async sureDelete(id) {
				const res = await baleDelete({
					Id: id
				})
				if (res.IsSuccess) {
					this.$message({showClose: true,
						message: '删除成功',
						type: 'success'
					})
					this.getList();
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},

		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}
</style>
